<template>
  <SignUp/>
</template>

<script>
import { defineComponent } from 'vue';
import SignUp from '@/components/Login/SignUp.vue';

export default defineComponent({
  name: 'SignUpView',
  components: { SignUp },
});
</script>

<style>
</style>
